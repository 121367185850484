import Container from "../components/Container/Container";
import {Form, Table, Tag} from "antd";
import {useContext, useEffect, useState} from "react";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import {convertUnixTimestampToDateTime, numberFormat} from "../helpers/helpers";
import getTransactions from "../api/bank-transaction/getTransactions";
import TextCopy from "../components/TextCopy";
import Search from "../components/Search/Search";
import getBanks from "../api/bank/getBanks";
import LoadingContext from "../contexts/LoadingContext";

function BankTransaction() {
    const [dataSource,setDataSource] = useState([]);
    const [tableLoading,setTableLoading] = useState(false);
    const accessToken = useAuthHeader();
    const [form] = Form.useForm();
    const [options, setOptions] = useState([]);
    const { setLoading } = useContext(LoadingContext);
    const [summaryData, setSummaryData] = useState({
        totalAmount: 0,
        totalFee: 0
    });
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const items = [
        {
            name: 'transactionId',
            type: 'text',
            placeholder: 'Mã giao dịch'
        },
        {
            name: 'accNo',
            type: 'text',
            placeholder: 'Tài khoản'
        },
        {
            name: 'content',
            type: 'text',
            placeholder: 'Nội dung'
        },
        {
            name: 'createdAt',
            type: 'date',
        }
    ]

    const columns = [
        {
            title: 'ID',
            dataIndex: 'uuid',
            align: 'center'
        },
        {
            title: 'Ngân hàng',
            dataIndex: 'bankName',
            align: 'center',
            filterMode: 'tree',
            filters: options,
            render: (value) => {
                return <Tag>{value}</Tag>
            }
        },
        {
            title: 'Tài khoản',
            dataIndex: 'username',
            align: 'center',
            render: (value) => {
                return <TextCopy value={value}/>
            }
        },
        {
            title: 'Mã giao dịch',
            dataIndex: 'transactionId',
            align: 'center',
            render: (value) => {
                return <TextCopy value={value}/>
            }
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            align: 'center',
            sorter: true,
            render: (value) => {
                return numberFormat(value);
            }
        },
        {
            title: 'Phí',
            dataIndex: 'fee',
            align: 'center'
        },
        {
            title: 'Chuyển/Nhận',
            dataIndex: 'io',
            align: 'center',
            filters: [
                {
                    text: 'Chuyển tiền',
                    value: -1
                },
                {
                    text: 'Nhận tiền',
                    value: 1
                }
            ],
            render: (value) => {
                if (value === 1) {
                    return <Tag color={'green'}>Nhận</Tag>
                }
                return <Tag color={'red'}>Chuyển</Tag>
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            align: 'center',
            render: (value) => {
                if (value) {
                    return <Tag color={'green'}>Thành công</Tag>
                }
                return <Tag color={'red'}>Lỗi</Tag>
            }
        },
        {
            title: 'Nội dung',
            dataIndex: 'content',
            align: 'center',
            width: '240px',
            render: (value) => {
                return <TextCopy value={value}/>
            }
        },
        {
            title: 'Thời gian',
            dataIndex: 'time',
            align: 'center',
            sorter: true,
            render: (value) => {
                return convertUnixTimestampToDateTime(value);
            }
        }
    ]
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sorter,
        });
    };

    const handleSearch =  () => {
        const params = form.getFieldsValue();
        setTableParams({
            ...tableParams,
            search: {
                ...params
            }
        })
    }

    const fetchDataTable = async () => {
        setTableLoading(true);
        const response = await getTransactions(accessToken,tableParams);
        if (response.success) {
            setDataSource(response.data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: response.data.total
                },
            });
            setSummaryData({
                totalAmount: response.data.totalAmount,
                totalFee: response.data.totalFee
            })
        }
        setTableLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        const fetchBanks = async () => {
            const response = await getBanks(accessToken);
            if (response.success) {
                setOptions(response.data.map(item => ({
                    text:  item.displayName,
                    value: item.bankName
                })))
            }
        }
        fetchBanks().finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        fetchDataTable();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        JSON.stringify(tableParams.sorter),
        JSON.stringify(tableParams.search),
        JSON.stringify(tableParams.filters),
    ]);

    return (
        <Container>
            <Search
                form={form}
                items={items}
                onSearch={handleSearch}
            />
            <Table
                rowKey={'uuid'}
                columns={columns}
                dataSource={dataSource}
                bordered={true}
                scroll={{
                    x: 1800
                }}
                style={{
                    fontWeight: 500,
                }}
                loading={tableLoading}
                pagination={tableParams.pagination}
                onChange={handleTableChange}
                summary={(data) => {

                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={4}>
                                Tổng tiền
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'}>
                                <Tag color={'blue'}>{numberFormat(summaryData.totalAmount)}</Tag>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'}>
                                <Tag color={'blue'}>{numberFormat(summaryData.totalFee)}</Tag>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}
            />
        </Container>
    )
}
export default BankTransaction;